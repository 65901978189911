// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authentication-js": () => import("./../../../src/pages/authentication.js" /* webpackChunkName: "component---src-pages-authentication-js" */),
  "component---src-pages-deploystatus-js": () => import("./../../../src/pages/@deploystatus.js" /* webpackChunkName: "component---src-pages-deploystatus-js" */),
  "component---src-pages-id-autentikasi-js": () => import("./../../../src/pages/id/autentikasi.js" /* webpackChunkName: "component---src-pages-id-autentikasi-js" */),
  "component---src-pages-id-index-js": () => import("./../../../src/pages/id/index.js" /* webpackChunkName: "component---src-pages-id-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-journal-list-temp-js": () => import("./../../../src/templates/journal-list-temp.js" /* webpackChunkName: "component---src-templates-journal-list-temp-js" */),
  "component---src-templates-journal-temp-js": () => import("./../../../src/templates/journal-temp.js" /* webpackChunkName: "component---src-templates-journal-temp-js" */)
}

